<template>
    <div class="mt-2">                
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Wait</label>
                <div class="field_wpr">
                    <input type="number" min="0" value="0">
                </div>
            </div>
            <div class="group_item">
                <div class="field_wpr">
                    <Multiselect v-model="days.value" v-bind="days"></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">What days of the week can we resume?</label>
                <ul class="day_listing">
                    <li>
                        <label for="mon" class="checkbox">
                            <input type="checkbox" id="mon" v-model="dayCheck.mon" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Mon</p>
                        </label>
                    </li>
                    <li>
                        <label for="tue" class="checkbox">
                            <input type="checkbox" id="tue" v-model="dayCheck.tue" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Tue</p>
                        </label>
                    </li>
                    <li>
                        <label for="wed" class="checkbox">
                            <input type="checkbox" id="wed" v-model="dayCheck.wed" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Wed</p>
                        </label>
                    </li>
                    <li>
                        <label for="thu" class="checkbox">
                            <input type="checkbox" id="thu" v-model="dayCheck.thu" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Thu</p>
                        </label>
                    </li>
                    <li>
                        <label for="fri" class="checkbox">
                            <input type="checkbox" id="fri" v-model="dayCheck.fri" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Fri</p>
                        </label>
                    </li>
                    <li>
                        <label for="sat" class="checkbox">
                            <input type="checkbox" id="sat" v-model="dayCheck.sat" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Sat</p>
                        </label>
                    </li>
                    <li>
                        <label for="sun" class="checkbox">
                            <input type="checkbox" id="sun" v-model="dayCheck.sun" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <p>Sun</p>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
        <TimeFields isTimezone/>
    </div>
</template>

<script>
    import TimeFields from './TimeFields.vue'
    import Multiselect from '@vueform/multiselect';
    export default{
        name: "DelaySchedule",
        components: {
            TimeFields,
            Multiselect,
        },
        data(){
            return{
                dayCheck: {
                    mon: true,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: false,
                },
                days: {
                    mode: 'single',
                    value: ['Days'],
                    options: [
                        { value: 'Days', label: 'Days' },
                        { value: 'Hours', label: 'Hours' },
                        { value: 'Minutes', label: 'Minutes' }
                    ],
                    createTag: true
                },
            }
        }
    }
</script>
