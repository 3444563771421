<template>
    <div class="mt-2">
        <TimeFields isTimezone/>
    </div>
</template>

<script>
    import TimeFields from './TimeFields.vue'
    export default{
        name: "TimeSchedule",
        components: {
            TimeFields
        },
    }
</script>
