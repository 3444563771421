<template>
    <ul class="tab_sec">
        <li @click="scheduleTab = 'DelaySchedule'" :class="{active: scheduleTab === 'DelaySchedule'}">Delay</li>
        <li @click="scheduleTab = 'Datetime'" :class="{active: scheduleTab === 'Datetime'}">Datetime</li>
        <li @click="scheduleTab = 'TimeSchedule'" :class="{active: scheduleTab === 'TimeSchedule'}">Time</li>
    </ul>
    <div class="tab_content">
        <keep-alive>
            <component :is="scheduleTab"></component>
        </keep-alive>
    </div>
</template>

<script>
    import Datetime from '@/components/Datetime';
    import DelaySchedule from '@/components/DelaySchedule';
    import TimeSchedule from '@/components/TimeSchedule';
    export default {
        name: 'Scheduletabs',
        components: {
            Datetime,
            DelaySchedule,
            TimeSchedule
        },
        data(){
            return{
                scheduleTab: 'DelaySchedule'
            }
        }
    }
</script>
