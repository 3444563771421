<template>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">{{title ? title :'What time of day should we resume?'}}</label>
            <div class="group-col-3">
                <div class="field_wpr">
                    <Multiselect v-model="hours.value" v-bind="hours"></Multiselect>
                </div>
                <div class="field_wpr">
                    <Multiselect v-model="mins.value" v-bind="mins"></Multiselect>
                </div>
                <div class="field_wpr">
                    <Multiselect v-model="clock.value" v-bind="clock"></Multiselect>
                </div>
            </div>
        </div>
    </div>
    <div class="form_grp" v-if="isTimezone">
        <div class="group_item">
            <label class="input_label">Timezone</label>
            <div class="field_wpr">
                <Multiselect v-model="timezone.value" v-bind="timezone"></Multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default{
    name: 'TimeFields',
    components:{
        Multiselect
    },
    props:{
        title: String,
        isTimezone: Boolean,
    },
    data(){
        return{
            mins: {
                mode: 'single',
                value: ['00'],
                options: [
                    { value: '00', label: '00' },
                    { value: '01', label: '01' },
                    { value: '02', label: '02' },
                    { value: '03', label: '03' },
                    { value: '04', label: '04' },
                    { value: '05', label: '05' },
                    { value: '06', label: '06' },
                    { value: '07', label: '07' },
                    { value: '08', label: '08' },
                    { value: '09', label: '09' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                    { value: '13', label: '13' },
                    { value: '14', label: '14' },
                    { value: '15', label: '15' },
                    { value: '16', label: '16' },
                    { value: '17', label: '17' },
                    { value: '18', label: '18' },
                    { value: '19', label: '19' },
                    { value: '20', label: '20' },
                    { value: '21', label: '21' },
                    { value: '22', label: '22' },
                    { value: '23', label: '23' },
                    { value: '24', label: '24' },
                    { value: '25', label: '25' },
                    { value: '26', label: '26' },
                    { value: '27', label: '27' },
                    { value: '28', label: '28' },
                    { value: '29', label: '29' },
                    { value: '30', label: '30' },
                    { value: '31', label: '31' },
                    { value: '32', label: '32' },
                    { value: '33', label: '33' },
                    { value: '34', label: '34' },
                    { value: '35', label: '35' },
                    { value: '36', label: '36' },
                    { value: '37', label: '37' },
                    { value: '38', label: '38' },
                    { value: '39', label: '39' },
                    { value: '40', label: '40' },
                    { value: '41', label: '41' },
                    { value: '42', label: '42' },
                    { value: '43', label: '43' },
                    { value: '45', label: '45' },
                    { value: '46', label: '46' },
                    { value: '47', label: '47' },
                    { value: '48', label: '48' },
                    { value: '49', label: '49' },
                    { value: '50', label: '50' },
                    { value: '51', label: '51' },
                    { value: '52', label: '52' },
                    { value: '53', label: '53' },
                    { value: '54', label: '54' },
                    { value: '55', label: '55' },
                    { value: '56', label: '56' },
                    { value: '57', label: '57' },
                    { value: '58', label: '58' },
                    { value: '59', label: '59' },
                ],
                createTag: true
            },
            hours: {
                mode: 'single',
                value: ['7'],
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
                createTag: true
            },
            clock: {
                mode: 'single',
                value: ['AM'],
                options: [
                    { value: 'AM', label: 'AM' },
                    { value: 'PM', label: 'PM' }
                ],
                createTag: true
            },
            timezone:{
                mode: 'single',
                value: ['gmt'],
                options: [
                    { value: 'gmt', label: 'GMT +00:00 Africa/Accra' },
                    { value: 'cat', label: 'CAT +02:00 Africa/Blantyre' },
                    { value: 'cet', label: 'CET +01:00 Africa/Algiers' },
                    { value: 'eat', label: 'EAT +03:00 Africa/Addis_Ababa'},
                    { value: 'eet', label: 'EET +02:00 Africa/Cairo'},
                    { value: 'wat', label: 'WAT +01:00 Africa/Bangui'}
                ],
                searchable: true,
                createTag: true
            }
        }
    }
}
</script>