<template>
    <div class="mt-2">
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Date</label>
                <div class="field_wpr">
                    <datepicker v-model="dates" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                </div>
            </div>
        </div>
        <TimeFields isTimezone/>
    </div>
</template>

<script>
    import TimeFields from './TimeFields.vue'
    export default{
        name: "Datetime",
        components: {
            TimeFields
        },
        data(){
            return{
                dates:'',
            }
        }
    }
</script>
